const highlights = [
  {
    text: "JOB: Full stack developer",
    date: "Today",
    details: "Full-time job as a full stack developer. Started in August 2021.",
    place: "Verendus",
    link: {
      url: "https://verendus.se/",
      text: "Company site",
    },
  },
  {
    text: "DEGREE: Full stack web developer",
    date: "2021",
    details:
      "Full Stack Development vocational education and training program (VET).",
    place: "Chas Academy",
    link: {
      url: "https://kunskapsbasen.chas.school/",
      text: "Program details",
    },
  },
  {
    text: "JOB: Full stack developer intern",
    date: "2020",
    details:
      "6-months internship. I worked building a serverless web application using AWS, React and GraphQL.",
    place: "Gleechi",
    link: {
      url: "https://gleechi.com/",
      text: "Company site",
    },
  },
  {
    text: "DEGREE: MSc in Geomatics",
    date: "2018",
    place: "Lunds universitet",
    details: `Thesis: "Assessing edge pixel classification in forest stands using a machine learning algorithm and Sentinel-2 data."`,
    link: {
      url: "https://lup.lub.lu.se/student-papers/search/publication/8954922",
      text: "Download thesis",
    },
  },
  {
    text: "DEGREE: Agronomist Engineer.",
    date: "2014",
    place: "Universidad Católica de Chile",
    details: `Major in environmental management. Thesis: "Assessment of rural and biodiversity conservation areas in Santiago".`,
    link: {
      url: "https://enzozerega-portfolio-files.s3.eu-north-1.amazonaws.com/BD_Thesis_Enzo_Zerega.pdf",
      text: "Download thesis",
    },
  },
];

export function getHighlights() {
  return highlights;
}
