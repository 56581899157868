import React from "react";
import { getHighlights } from "../services/Highlights";
import "../style/Timeline.scss";

const TimelineItem = ({ data }) => (
  <div className="timeline-item">
    <div className="timeline-item-content">
      <time>{data.date}</time>
      <p className="timeline-title">{data.text}</p>
      <p className="timeline-place">{data.place}</p>
      <div className="timeline-details">
        <p>
          {data.details}{" "}
          {data.link && (
            <a href={data.link.url} target="_blank" rel="noopener noreferrer">
              {data.link.text}
            </a>
          )}
        </p>
      </div>
    </div>
    <span className="circle" />
  </div>
);

export const Highlights = () =>
  getHighlights().length > 0 && (
    <div className="timeline-container">
      {getHighlights().map((data, idx) => (
        <TimelineItem data={data} key={idx} />
      ))}
    </div>
  );
