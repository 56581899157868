const proyects = [
  {
    title: "Car Rental",
    description: "Register system for a car rental company",
    tags: "PHP, MySQL, CSS, JavaScript",
    github: "https://github.com/enzozerega/u05-php-car-rental",
    // www: "https://u05-php-car-rental.herokuapp.com/",
    img: "carrental",
  },
  {
    title: "Movies App",
    description: "Find movies and actors",
    tags: "Angular, TypeScript, OpenWeatherMap API",
    github: "https://github.com/enzozerega/u08-angular-movie-app",
    // www: "https://u08-angular-movie-app.web.app/",
    img: "movieapp",
  },
  {
    title: "Mediaspace",
    description: "Post feed build with React",
    tags: "React, MongoDB, Node.js",
    github: "https://github.com/enzozerega/u11-mediaspace",
    // www: "https://u11-mediaspace-app.herokuapp.com/",
    img: "mediaspace",
  },
  {
    title: "Hangman",
    description: "A simple game",
    tags: "HTML, CSS, JavaScript",
    github: "https://github.com/enzozerega/u03-javascript-hangman",
    // www: "https://u03-javascript-hangman.web.app",
    img: "hangman",
  },
  {
    title: "MSc Thesis",
    description: "Thesis I wrote to obtain my MSc in Geomatics",
    tags: "GIS, R, Machine Learning",
    label: "Details",
    www: "https://lup.lub.lu.se/student-papers/search/publication/8954922",
    img: "geomatics",
  },
  {
    title: "ArcticDEM data",
    description: "ArcGIS toolbox",
    tags: "Python, ArcPy, ArcticDEM, GIS",
    label: "Details",
    www: "https://www.nateko.lu.se/article/students-created-toolbox-for-gis-software",
    img: "toolbox",
  },
  {
    title: "My Portfolio",
    description: "My web developer portfolio",
    tags: "React, SCSS, Google Cloud",
    github: "https://github.com/enzozerega/portfolio",
    // www: "https://enzozerega.com",
    img: "portfolio",
  },
  {
    title: "Magic Crystals",
    description: "Online store. My first website ever!",
    tags: "HTML, CSS",
    github: "https://github.com/enzozerega/u01-static-website",
    // www: "https://u01-static-website.web.app",
    img: "magic-crystals",
  },
  {
    title: "Speak Up",
    description: "T-shirts web store",
    tags: "Wordpress, Elementor",
    // www: "http://enzo.zerega.chas.academy/u07-wordpress/",
    img: "tshirtsstore",
  },
  {
    title: "Weather App",
    description: "Check the weather",
    tags: "React, CSS, The Movie Database API",
    github: "https://github.com/enzozerega/u09-react-weather-app",
    // www: "https://u09-react-weather-app.web.app/",
    img: "weatherapp",
  },
];

export default function getProyects() {
  return proyects;
}
