import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "../style/NavBar.scss";

export default function NavBar({ fadeIn, setFadeIn }) {
  const location = useLocation();
  const [specialClass, setSpecialClass] = useState("navbar-item");
  const [darkBg, setDarkBg] = useState(false);

  let isOpen = false;

  useEffect(() => {
    if (location.pathname !== "/") {
      setSpecialClass("navbar-item-dark");
      setDarkBg(true);
      if (fadeIn) setFadeIn(false);
    } else {
      setSpecialClass("navbar-item");
      setDarkBg(false);
    }
  }, [location.pathname, fadeIn, setFadeIn]);

  const handleMenuIconClick = () => {
    const icon = document.getElementById("nav-drawer-icon");
    const menu = document.getElementById("custom-id");
    const shadow = document.getElementById("shadow");

    if (!isOpen) {
      icon.classList.add("rotate");
      menu.classList.add("translate-x");
      shadow.classList.add("visible");
    } else {
      icon.classList.remove("rotate");
      menu.classList.remove("translate-x");
      shadow.classList.remove("visible");
    }

    isOpen = !isOpen;
  };

  const Links = () => (
    <ul>
      <li>
        <NavLink className={specialClass} to="/about">
          About
        </NavLink>
      </li>
      <li>
        <NavLink className={specialClass} to="/projects">
          Projects
        </NavLink>
      </li>
    </ul>
  );

  const HomeLink = ({ containerClass }) => (
    <div className={containerClass}>
      <NavLink className="nav-home-link" to="/">
        <div className={`nav-logo-container ${darkBg ? "dark-bg" : ""}`}></div>
        <div className={`nav-logo-img ${darkBg ? "dark-bg" : ""}`}></div>
      </NavLink>
    </div>
  );

  const CtaButton = ({ containerClass }) => (
    <div className={containerClass}>
      <NavLink className={specialClass} to="/contact">
        Contact me
      </NavLink>
    </div>
  );

  const NavDrawer = () => (
    <div className="nav-drawer-container">
      <div id="nav-drawer-icon" onClick={handleMenuIconClick}>
        {[...Array(4)].map((i, index) => (
          <span
            key={`nav-drawer-icon-line-${index}`}
            className={`${darkBg ? "dark-icon" : ""}`}
          ></span>
        ))}
      </div>
      <nav id="custom-id" className={`nav-drawer-menu`}>
        <HomeLink containerClass="nav-drawer-home-link" />
        <Links />
        <CtaButton containerClass="nav-drawer-cta" />
      </nav>
      <div
        id="shadow"
        className="nav-drawer-shadow"
        onClick={handleMenuIconClick}
      ></div>
    </div>
  );

  return (
    <header className={fadeIn ? "fade-in" : ""}>
      <nav>
        <HomeLink containerClass="nav-home" />
        <Links />
        <CtaButton containerClass="cta flend" />
      </nav>
      <NavDrawer />
    </header>
  );
}
