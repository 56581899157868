import React, { Fragment } from "react";
import { motion } from "framer-motion";
import { animation } from "./common/Animations";
import "../style/About.scss";
import MyStory from "./MyStory";
import Skills from "./Skills";
import ImagePL from "./common/ImagePL";
import { Highlights } from "./Highlights";

const About = () => {
  //Progressive image loading.
  //From: https://jmperezperez.com/medium-image-progressive-loading-placeholder/

  return (
    <Fragment>
      <motion.section
        variants={animation}
        initial="initial"
        animate="animate"
        exit="exit"
        className="about"
      >
        <article className="about-intro">
          <ImagePL />
          <div className="about-intro-text">
            <p>
              <span className="greeting">Hi!</span> I'm Enzo.
            </p>
            <p>
              I’m currently based in Sweden, and in 2019 I decided to start a
              career on web development. I have one year of relevant working
              experience as a <strong>Full stack developer</strong>.
            </p>
            <p>
              My latest job was at Verendus, a company that delivers a Software
              as a Service (SaaS) solution for vehicle dealers in Sweden, Norway
              and Germany.
            </p>
          </div>
        </article>
        <article className="education">
          <h1>Highlights</h1>
          <Highlights />
        </article>
        <article className="skills">
          <h1>Skills</h1>
          <Skills />
        </article>
        <article className="my-story">
          <h1>My Story</h1>
          <MyStory />
          <div className="cta">
            <a href="/contact">Contact me</a>
          </div>
        </article>
      </motion.section>
    </Fragment>
  );
};

export default About;
