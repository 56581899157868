import React, { Fragment } from "react";
import { motion } from "framer-motion";
import { animation } from "./common/Animations";
import BackgroundVideo from "./BackgroundVideo";
import { Typewriter } from "react-simple-typewriter";
import "../style/Home.scss";
import { useEffect } from "react";
import { useState } from "react";

const Home = ({ fadeIn, setFadeIn }) => {
  const [typewriter, setTypewriter] = useState(false);

  useEffect(() => {
    if (fadeIn)
      setTimeout(() => {
        setFadeIn(false);
        setTypewriter(true);
      }, 2001);
    else setTypewriter(true);
  }, [fadeIn, setFadeIn]);
  return (
    <Fragment>
      <motion.section
        initial="initial"
        animate="animate"
        exit="exit"
        className={`hero ${fadeIn ? "fade-in" : ""}`}
      >
        <motion.div variants={animation} className="hero-title-container">
          <div className="hero-title-content">
            <div className="title-text">
              <h3>Enzo Zerega</h3>
              <h1>
                {typewriter && (
                  <Typewriter
                    words={[
                      "Web Developer",
                      "JavaScript Developer",
                      "PHP Developer",
                    ]}
                    cursor
                    cursorStyle="_"
                    loop
                    typeSpeed={100}
                    delaySpeed={4000}
                  />
                )}
              </h1>
            </div>
          </div>
        </motion.div>
      </motion.section>
      <BackgroundVideo fadeIn={fadeIn} setFadeIn={setFadeIn} />
    </Fragment>
  );
};

export default Home;
