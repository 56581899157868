import React from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import "../style/BackgroundVideo.scss";

const BackgroundVideo = ({ fadeIn, setFadeIn }) => {
  useEffect(() => {
    document.getElementById("background-video").playbackRate = 0.7;
  }, []);
  return (
    <Fragment>
      <div className={`shadow ${fadeIn ? "fade-in-shadow" : ""}`} />
      <video
        autoPlay
        loop
        muted
        className={`background-video ${fadeIn ? "fade-in" : ""}`}
        id="background-video"
      >
        <source
          src="https://enzozerega-portfolio-files.s3.eu-north-1.amazonaws.com/background-video.mp4"
          type="video/mp4"
        />
      </video>
    </Fragment>
  );
};

export default BackgroundVideo;
